/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "chaneyultra_extended";
  src: url("chaney-ultraextended-webfont.eot");
  src: url("chaney-ultraextended-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("chaney-ultraextended-webfont.woff2") format("woff2"),
    url("chaney-ultraextended-webfont.woff") format("woff"),
    url("chaney-ultraextended-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.clip-box {
  clip-path: polygon(
    0 44.38px,
    35px 35px,
    44.38px 0,
    calc(100% - 44.38px) 0,
    calc(100% - 35px) 35px,
    100% 44.38px,
    100% calc(100% - 44.38px),
    calc(100% - 35px) calc(100% - 35px),
    calc(100% - 44.38px) 100%,
    44.38px 100%,
    35px calc(100% - 35px),
    0 calc(100% - 44.38px)
  );
}
.hablemos::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  clip-path: polygon(
    0 44.38px,
    35px 35px,
    44.38px 0,
    calc(100% - 44.38px) 0,
    calc(100% - 35px) 35px,
    100% 44.38px,
    100% calc(100% - 44.38px),
    calc(100% - 35px) calc(100% - 35px),
    calc(100% - 44.38px) 100%,
    44.38px 100%,
    35px calc(100% - 35px),
    0 calc(100% - 44.38px)
  );
}

#legal h1,
#legal h2,
#legal h3,
#legal a,
#legal strong,
#legal li::marker {
  color: #357cf4;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
